<template>
  <!-- 右边表单区块 -->
  <Form ref="formValidate" :model="partyForm" :label-width="100" :label-colon="true">
    <FormItem label="所在党支部" class="form-item">
      <Input :maxlength="20" v-model.trim="partyForm.cbranch" placeholder="唯一性的编码" />
    </FormItem>
    <FormItem label="党内职务" class="form-item">
      <Select v-model="partyForm.communistPost" clearable>
        <Option v-for="item in communistPost" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
      </Select>
    </FormItem>
     <FormItem label="入党时间" class="form-item">
      <DatePicker type="date" v-model="partyForm.joinDate"></DatePicker>
    </FormItem>
    <FormItem label="单位党组织联系电话" class="form-item">
      <Input :maxlength="20" v-model.trim="partyForm.unitTel" />
    </FormItem>
    <FormItem label="进入支部类型" class="form-item">
      <Select v-model="partyForm.joinType" clearable>
        <Option v-for="item in joinType" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
      </Select>
    </FormItem>
    <FormItem label="转离支部类型" class="form-item">
      <Select v-model="partyForm.leaveType" clearable>
        <Option v-for="item in leaveType" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
      </Select>
    </FormItem>
    <FormItem label="是否加入党员服务队" class="form-item">
      <Select v-model="partyForm.joinService" clearable>
        <Option v-for="item in joinService" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
      </Select>
    </FormItem>
    <FormItem label="能参加社区服务的时间" class="form-item">
      <Select v-model="partyForm.serviceTime" clearable>
        <Option v-for="item in serviceTime" :value="item.dictKey" :key="item.dictKey">{{ item.dictValue }}</Option>
      </Select>
    </FormItem>
    <FormItem label="服务意向" class="form-item" style="width: 92%">
      <Input
        v-model.trim="partyForm.serviceIntention"
        type="textarea"
        show-word-limit
        :autosize="{minRows: 4,maxRows: 6}"
        :maxlength="800"
        placeholder="请输入服务意向(请控制在800字以内)"
      ></Input>
    </FormItem>
  </Form>
</template>

<script>
export default {
  props: ["partyForm"],
  data() {
    return {
      //党位职务
      communistPost: [],
      //进入支部类型
      joinType: [],
      //转离支部类型
      leaveType: [],
      //是否加入党员服务队
      joinService: [
        {
          dictValue: "是",
          dictKey: "1"
        },
        {
          dictValue: "否",
          dictKey: "2"
        },
      ],
      //能参加社区服务的时间
      serviceTime: [],
    };
  },
  created() {
     //获取党位职务
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNIST_POST",
    }).then((res) => {
     if(res.code == 200) {
        this.communistPost = res.dataList
     }
    });
     //进入支部类型
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNSIT_JOIN_TYPE",
    }).then((res) => {
     if(res.code == 200) {
        this.joinType = res.dataList
     }
    });
     //转离支部类型
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNSIT_LEAVE_TYPE",
    }).then((res) => {
     if(res.code == 200) {
        this.leaveType = res.dataList
     }
    });
     //能参加社区服务的时间
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNSIT_SERVICE_TIME",
    }).then((res) => {
     if(res.code == 200) {
        this.serviceTime = res.dataList
     }
    });
  },
};
</script>
    
<style scoped lang='less'>
.ivu-form {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .form-item {
    width: 42%;
    /deep/.ivu-form-item-label {
      text-align: right;
    }
    .ivu-date-picker {
      width: 100%;
    }
  }
  .nextStep {
    width: 100%;
    text-align: center;
    button {
      margin: 20px;
    }
  }
}
</style>