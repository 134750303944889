<template>
  <div>
    <!-- 右边表单区块 -->
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-colon="true" :label-width="90">
      <FormItem label="档案编号" prop="userCode" class="form-item">
        <Input :maxlength="20" v-model.trim="formValidate.userCode" placeholder="唯一性的编码" :disabled="replaceFormSign == true ? true : false" />
      </FormItem>
      <FormItem label="所属社区" prop="projectCode" class="form-item">
        <Tooltip max-width="280" placement="top" content="由于社区太多，请输入关键字进行刷选">
        <Select v-model="formValidate.projectCode" filterable :remote-method="loadingCommunityFn" :loading="loadingCommunity">
          <Option
            v-for="(item,index) in communityList"
            :value="item.code"
            :key="index"
          >{{ item.fullName }}</Option>
        </Select>
        </Tooltip>
      </FormItem>
      <FormItem label="姓名" prop="name" class="form-item">
        <Input :maxlength="20" v-model.trim="formValidate.name" />
      </FormItem>
      <FormItem label="性别" prop="sex" class="form-item">
        <Select v-model="formValidate.sex" clearable>
          <Option value="1">男</Option>
          <Option value="2">女</Option>
        </Select>
      </FormItem>
      <FormItem label="出生年月" class="form-item">
        <DatePicker type="date" placeholder="请选择出生年月" v-model="formValidate.birthday"></DatePicker>
      </FormItem>
      <FormItem label="民族" class="form-item">
        <Select v-model="formValidate.national" clearable>
          <Option v-for="(item,index) in nationList" :value="item.dictKey" :key="index">{{ item.dictValue }}</Option>
        </Select>
      </FormItem>
      <FormItem label="籍贯" class="form-item">
        <Input :maxlength="20" v-model.trim="formValidate.hometown" />
      </FormItem>
      <FormItem label="身份证号" class="form-item" prop="idNum">
        <Input :maxlength="20" v-model.trim="formValidate.idNum" @on-blur="idNumSearch" />
      </FormItem>
      <FormItem label="学历" class="form-item">
        <Select v-model="formValidate.eduDegree" clearable>
          <Option v-for="(item,index) in educationList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="手机号码" class="form-item" prop="account">
        <Input :maxlength="20" v-model.trim="formValidate.account" @on-blur="accountSearch" />
      </FormItem>
      <FormItem label="所在单位" class="form-item" style="width: 92%">
        <Input :maxlength="20" v-model.trim="formValidate.workUnit" />
      </FormItem>
      <FormItem label="职业分类" class="form-item">
        <Select v-model="formValidate.skill" clearable>
          <Option
            v-for="item in workerList"
            :value="item.dictKey"
            :key="item.dictKey"
          >{{ item.dictValue }}</Option>
        </Select>
      </FormItem>
      <FormItem label="单位职务" class="form-item">
        <Select v-model="formValidate.post" clearable>
          <Option
            v-for="(item,index) in positionList"
            :value="item.dictKey"
            :key="index"
          >{{ item.dictValue }}</Option>
        </Select>
      </FormItem>
    </Form>

    <!-- 弹出框 -->
    <Modal v-model="modal" title="警告" @on-ok="ok" :loading="true">
      <p>{{desc}}</p>
    </Modal>
  </div>
</template>

<script>
import { idcard, validateMobile } from "@/utils/validate.js";
export default {
  props: ["formValidate","replaceFormSign"],
  data() {
    return {
      //所属社区全部数据
      allCommunityList: [],
      loadingCommunity: false,
      //控制弹出框显示与否
      modal: false,
      //临时变量，存储身份证或手机号重复时的数据
      newUserData: [],
      desc: '',
      //社区列表
      communityList: [],
      //民族列表
      nationList: [],
      //学历列表
      educationList: [],
      //职业分类列表
      workerList: [],
      //单位职务列表
      positionList: [],
      //表单验证规则
      ruleValidate: {
        userCode: [
          {
            required: true,
            message: "请输入档案编号",
            trigger: "blur",
          },
        ],
        projectCode: [
          {
            required: true,
            message: "请输入所属社区",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请输入性别",
            trigger: "change",
          },
        ],
        idNum: [
          {
            required: true,
            validator: idcard,
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            validator: validateMobile,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //异步搜索所属社区
    loadingCommunityFn(query) {
      if(query !== '') {
        this.loadingCommunity = true;
       this.communityList = this.allCommunityList.filter(item => item.fullName.toLowerCase().indexOf(query.toLowerCase()) > -1);
       if(this.communityList.length > 50) this.communityList.length = 50;
        this.loadingCommunity = false;
      }else {
        this.communityList = []
      }
    },
    //ok弹出框确定
    ok() {
       setTimeout(() => {
        this.modal = false;
        this.$emit("replaceFormData", this.newUserData);
        //替换数据后重新校验表单
        this.$refs["formValidate"].validate();
      }, 1000);
    },
   
    //身份证输入框失焦判断
    idNumSearch(value) {
      let idNum = value.target.value;
      this.$get("/syaa/pc/sy/user/communist/selectByIdNum", {
        idNum,
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.modal = true;
          res.data.sex = res.data.sex == '男' ? '1' : '2';
          this.newUserData = res.data;
          this.desc = '当前身份证号码已存在，是否覆盖？'
        }
      });
    },
    //手机号码失焦判断
    accountSearch(value) {
      let phone = value.target.value;
      this.$get("/syaa/pc/sy/user/communist/selectByAccount", {
        phone,
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.modal = true;
          res.data.sex = res.data.sex == '男' ? '1' : '2';
          this.newUserData = res.data;
          this.desc = '当前手机已存在，是否覆盖？'
        }
      });
    },
  },
  created() {
    //获取所属社区列表
    let staffId = parent.vue.loginInfo.userinfo.id;
    this.$get("/old/api/pc/project/selectStaffManagerProjectAndOrg", {
      staffId: staffId,
      orgCode: parent.vue.loginInfo.userinfo.orgCode
    }).then((res) => {
      if (res.code == 200) {
        this.allCommunityList = JSON.parse(JSON.stringify(res.dataList)); 
        res.dataList.length = res.dataList.length > 50 ? 50 : res.dataList.length;
        this.communityList = res.dataList;
        if(this.formValidate.projectCode) {
          this.allCommunityList.map(item => {
            if(item.code == this.formValidate.projectCode) {
              this.communityList.unshift(item);
            }
          })
        }
      }
    });
    //获取学历列表
    this.$get("/old/api/common/sys/dictList", {
      groupCode: "EDU_DEGREE",
    }).then((res) => {
      this.educationList = res.dataList;
    });
    //获取职业分类列表
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNIST_SKILL",
    }).then((res) => {
      if (res.code == 200) {
        this.workerList = res.dataList;
      }
    });
    //获取单位职务
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "COMMUNIST_POST",
    }).then((res) => {
      if (res.code == 200) {
        this.positionList = res.dataList;
      }
    });
    //获取民族列表
    this.$get("/datamsg/api/common/sys/findDictByDictType", {
      dictType: "NATIONAKLITY",
    }).then((res) => {
      if (res.code == 200) {
        this.nationList = res.dataList;
      }
    });
  },
};
</script>
    
<style scoped lang='less'>
.ivu-form {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .form-item {
    margin-bottom: 20px;
    width: 42%;
    .ivu-date-picker {
      width: 100%;
    }
  }
}
</style>